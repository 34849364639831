import * as React from 'react';
import StepWrapper from '../StepWrapper/StepWrapper';
import ShippingAddressStepDone from './ShippingAddressStepDone';
import ShippingAddressStepEdit from './ShippingAddressStepEdit';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import { Order, Shipment } from 'api/orders/types';
import { CheckoutStep } from '../StepsContext/StepsContext';
import ValidateAddressContextContainer from 'contexts/ValidateAddressContext/ValidateAddressContextContainer';
import { FormikValues, useFormikContext } from 'formik';
import OrderContext from 'app/contexts/OrderContext/OrderContext';

interface Props {
  addresses: Address[];
  newAddress: boolean;
  setNewAddress: (newAddress: boolean) => void;
  order: Order;
  shipment: Shipment;
}

const ShippingAddressStep = (props: Props) => {
  const { setNewAddress } = props;
  const formikProps = useFormikContext<FormikValues>();
  const { order } = React.useContext(OrderContext);
  React.useEffect(() => {
    const isNewVerifiedAddressSaved =
      formikProps.values?.shipAddressAttributes?.verified && order?.shippingAddress?.id;
    if (isNewVerifiedAddressSaved) {
      formikProps.setFieldValue('shipAddressId', order.shippingAddress.id);
      setNewAddress(false);
    }
  }, [setNewAddress, order?.shippingAddress?.id]);

  return (
    <StepWrapper
      step={CheckoutStep.Address}
      title="Address"
      EditComponent={
        <ValidateAddressContextContainer
          onSaveValidAddress={(address: Address) => {
            formikProps.setFieldValue('shipAddressAttributes', address);
            formikProps.submitForm();
          }}
          allowUnverifiedStreet
          selectAddressWithoutSaving={!formikProps.values.saveNewShippingAddress}
        >
          <ShippingAddressStepEdit {...props} />
        </ValidateAddressContextContainer>
      }
      DoneComponent={<ShippingAddressStepDone />}
    />
  );
};

export default ShippingAddressStep;
