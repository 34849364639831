import * as React from 'react';
import OrderContext from 'contexts/OrderContext/OrderContext';
import AddressDescription from 'bundles/App/pages/CheckoutPage/Steps/AddressDescription/AddressDescription';
import CreditCardLabel from 'bundles/App/pages/CheckoutPage/Steps/PaymentStep/CreditCardLabel';
import { CreditCard } from 'bundles/App/pages/Account/CreditCards/types';
import { Payment, StoreCredit } from 'api/orders/types';
import { formatMoney } from 'utils/money';

interface Props {
  displayAddress?: boolean;
}

const PaymentStepDone = ({ displayAddress }: Props) => {
  const { order } = React.useContext(OrderContext);
  const payments = order.payments;

  function instanceOfCreditCard(object: CreditCard | StoreCredit): object is CreditCard {
    return 'lastDigits' in object;
  }

  function instanceOfStoreCredit(object: CreditCard | StoreCredit): object is StoreCredit {
    return 'memo' in object;
  }

  return (
    <div className="flex">
      {displayAddress && (
        <div className="mr-12">
          <div className="paragraph-bold-mobile mb-1">Billing Address</div>
          <AddressDescription address={order.billingAddress} />
        </div>
      )}
      <div className="flex flex-col space-y-2">
        {payments
          .filter(p => p.state === 'checkout')
          .map((payment: Payment, i: number) => (
            <>
              {instanceOfCreditCard(payment.source) && (
                <div>
                  {payments.length > 1 && (
                    <div className="paragraph-bold-mobile mb-0.5">Payment #{i + 1}</div>
                  )}
                  <div className="paragraph-medium-mobile mb-1">Credit Card</div>
                  <div className="flex">
                    <CreditCardLabel paymentSource={payment.source} />
                  </div>
                </div>
              )}
              {instanceOfStoreCredit(payment.source) && (
                <div>
                  {payments.length > 1 && (
                    <div className="paragraph-bold-mobile mb-0.5">Payment #{i + 1}</div>
                  )}
                  <div data-cy="paymentStepDone.storeCreditTitle" className="paragraph-medium-mobile mb-1">
                    Store Credit ({payment.number})
                  </div>
                  <div data-cy="paymentStepDone.storeCreditValue" className="flex text-sm leading-normal">
                    {formatMoney(payment.amount)}
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  );
};
export default PaymentStepDone;
