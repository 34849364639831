import apiCall from 'app/libs/utils/api/apiCall';
import { ApiResponse, SharedErrors } from 'libs/utils/api/types';
import { Order } from 'api/orders/types';

export interface CheckoutErrors extends SharedErrors {
  order: Order;
  isRisky: boolean;
}

// PATCH /checkout/update/:state
export const checkoutOrder = (orderData, state): ApiResponse<Order, CheckoutErrors> =>
  apiCall.patch<Order, CheckoutErrors>({
    url: `/checkout/update/${state}`,
    data: { ...orderData },
    unscoped: true,
  });
