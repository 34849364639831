import * as React from 'react';
import StepWrapper from '../StepWrapper/StepWrapper';
import PaymentStepDone from './PaymentStepDone';
import PaymentStepEdit, { PaymentStepFormType } from './PaymentStepEdit';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import { CheckoutStep } from '../StepsContext/StepsContext';
import OrderContext from 'app/contexts/OrderContext/OrderContext';
import { FormikValues, useFormikContext } from 'formik';

interface Props {
  addresses: Address[];
  newAddress: boolean;
  setNewAddress: (newAddress: boolean) => void;
  newPayment: boolean;
  setNewPayment: (newPayment: boolean) => void;
}

const PaymentStep = <FormikPropsType extends PaymentStepFormType>(props: Props) => {
  const { setNewAddress } = props;
  const formikProps = useFormikContext<FormikValues>();
  const { order } = React.useContext(OrderContext);
  React.useEffect(() => {
    const isNewVerifiedAddressSaved =
      formikProps.values?.billAddressAttributes?.name && order?.billingAddress?.id;
    if (isNewVerifiedAddressSaved) {
      formikProps.setFieldValue('billAddressId', order.billingAddress.id);
      setNewAddress(false);
    }
  }, [setNewAddress, order?.billingAddress?.id]);

  return (
    <StepWrapper
      step={CheckoutStep.Payment}
      title="Payment"
      EditComponent={<PaymentStepEdit<FormikPropsType> {...props} />}
      DoneComponent={
        <div className="mb-4">
          <PaymentStepDone displayAddress />
        </div>
      }
    />
  );
};

export default PaymentStep;
