import React from 'react';
import OrderContext from 'app/contexts/OrderContext/OrderContext';
import { useHistory } from 'react-router';
import Toast from 'utils/toast';

// eslint-disable-next-line no-shadow
export enum CheckoutStep {
  Address = 1,
  Delivery,
  Payment,
  Confirm,
}

const steps = {
  address: CheckoutStep.Address,
  delivery: CheckoutStep.Delivery,
  payment: CheckoutStep.Payment,
  confirm: CheckoutStep.Confirm,
};

const INVALID_STATE = 'INVALID_STATE';

const getCurrentStep = (state: string) => {
  const step = steps[state];
  if (step) {
    return step;
  }
  return INVALID_STATE;
};

type OrderState = 'address' | 'delivery' | 'payment' | 'confirm' | typeof INVALID_STATE;

const getOrderState = (step: CheckoutStep): OrderState => {
  switch (step) {
    case CheckoutStep.Address:
      return 'address';
    case CheckoutStep.Delivery:
      return 'delivery';
    case CheckoutStep.Payment:
      return 'payment';
    case CheckoutStep.Confirm:
      return 'confirm';
    default:
      return INVALID_STATE;
  }
};

type StepsContextType = {
  currentStep: CheckoutStep;
  lastReachedStep: CheckoutStep;
  handleNextStep: (orderStep: string) => void;
  orderState: OrderState;
  setCurrentStep: (step: CheckoutStep) => void;
};

export const StepsContext = React.createContext<StepsContextType>(null);

const StepsProvider = ({ children }) => {
  const { order } = React.useContext(OrderContext);
  const history = useHistory();

  const [state, setState] = React.useState({
    currentStep: getCurrentStep(order.state),
    lastReachedStep: getCurrentStep(order.state),
  });

  const handleNextStep = (orderStep: string) => {
    const newStep = getCurrentStep(orderStep);
    setState({
      currentStep: newStep,
      lastReachedStep: newStep,
    });
  };

  const setCurrentStep = (step: CheckoutStep) => {
    setState({
      ...state,
      currentStep: step,
    });
  };

  const memoizedValue = React.useMemo(
    () => ({
      currentStep: state.currentStep,
      lastReachedStep: state.lastReachedStep,
      handleNextStep,
      orderState: getOrderState(state.currentStep),
      setCurrentStep,
    }),
    [state],
  );

  if (
    state.currentStep === INVALID_STATE ||
    state.lastReachedStep === INVALID_STATE ||
    memoizedValue.orderState === INVALID_STATE
  ) {
    history.push('/cart');
    Toast.error('Error: Invalid cart state');
  }

  return <StepsContext.Provider value={memoizedValue}>{children}</StepsContext.Provider>;
};

export default StepsProvider;
