import SimplyShip from 'bundles/App/pages/CartPage/MiddleSection/SimplyShip/SimplyShip';
import * as React from 'react';
import Button from 'styleguide/components/Button/Button';
import ProductsTable from 'styleguide/components/ProductsTable/ProductsTable';
import OrderContext from 'contexts/OrderContext/OrderContext';
import SimplyShipContextContainer from 'app/contexts/SimplyShipContext/SimplyShipContextContainer';
import TempOrderTotalsContext from 'contexts/TempOrderTotalsContext/TempOrderTotalsContext';

const DeliveryStepEdit = () => {
  const { dispatch, order } = React.useContext(OrderContext);
  const tempOrderTotalsContext = React.useContext(TempOrderTotalsContext);

  return (
    <SimplyShipContextContainer
      admin={false}
      save
      order={order}
      dispatch={dispatch}
      setOrderTotals={tempOrderTotalsContext.setOrderTotals}
    >
      <div className="paragraph-bold-mobile mb-6">Simply Ship™️ Cost Estimator</div>
      <SimplyShip checkoutPageMode disableInput />
      <ProductsTable order={order} isLoading={order.status === 'loading'} checkoutMode />
      <Button
        data-cy="deliveryStepBtn"
        className="w-full !px-0 !font-hvMedium !text-base"
        type="submit"
        color="orange"
        disabled={
          order.status === 'loading' ||
          !(typeof order.status === 'string' || order.status instanceof String) ||
          order.shipment?.quoteState === 'invalid'
        }
      >
        Add Payment
      </Button>
    </SimplyShipContextContainer>
  );
};
export default DeliveryStepEdit;
