import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

interface NumericProps extends SvgProps {
  value: string;
}
// #C4C4C4
const IconNumeric = ({
  value,
  title = '',
  color = 'mediumGray',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: NumericProps) => {
  const white = 'white';
  return (
    <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
      <g>
        <circle fill={colors[color]} cx="25" cy="25" r="24" />
      </g>
      <text
        fill={colors[white]}
        fontFamily="HelveticaNeueLTPro-Bd"
        fontSize="24px"
        transform="matrix(1 0 0 1 18.7754 33.7188)"
      >
        {value}
      </text>
    </SvgIcon>
  );
};

export default IconNumeric;
