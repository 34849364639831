import * as React from 'react';
import cn from 'classnames';
import { AiFillEdit } from '@react-icons/all-files/ai/AiFillEdit';
import { CheckoutStep, StepsContext } from '../StepsContext/StepsContext';

interface Props {
  title: string;
  step: CheckoutStep;
  children?: React.ReactNode;
  EditComponent?: React.ReactNode;
  DoneComponent?: React.ReactNode;
}

const StepWrapper = ({ step, title, EditComponent, DoneComponent, children }: Props) => {
  const { currentStep, lastReachedStep, setCurrentStep } = React.useContext(StepsContext);

  const isStepIncomplete = lastReachedStep < step;
  const canEdit = lastReachedStep > step && currentStep !== step;

  const stepNumberClassName = cn('cation-bold text-gray-500', { 'text-gray-300': isStepIncomplete });
  const titleClassName = cn('sub-heading-bold', { 'text-gray-300': isStepIncomplete });

  const showEditComponent = currentStep === step && EditComponent;
  const showDoneComponent = lastReachedStep > step && currentStep !== step && DoneComponent;

  return (
    <div className="mb-6 border-b border-gray-50 pb-6 last:mb-0 last:border-none last:pb-0">
      <div className="flex items-center justify-between pl-5 pr-5 pt-5">
        <div>
          <div className={stepNumberClassName}>{`0${step}`}</div>
          <div data-cy={`checkoutStep-${title}`} className={titleClassName}>
            {title}
          </div>
        </div>
        {canEdit && (
          <div data-cy={`checkoutEditBtnStep-${title}`} className="h-4 w-4 cursor-pointer">
            <AiFillEdit className="h-4 w-4" onClick={() => setCurrentStep(step)} />
          </div>
        )}
      </div>
      <div className="px-20 pt-10 -md:!px-5">
        <div data-cy={`checkoutEditStep-${title}`}>{showEditComponent && EditComponent}</div>
        <div data-cy={`checkoutDoneStep-${title}`}>{showDoneComponent && DoneComponent}</div>
        {children}
      </div>
    </div>
  );
};

export default StepWrapper;
