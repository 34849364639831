import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconCircleCheck = ({
  title = 'Check',
  color = 'green',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => {
  const white = 'white';
  return (
    <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
      <g fill={colors[color]}>
        <circle cx="25" cy="25" r="24" />
      </g>
      <g fill={colors[white]} fillRule="evenodd" clipRule="evenOdd">
        <path
          d="M36.2,33.6L36.2,33.6L36.2,33.6z M36.2,16L36.2,16L36.2,16L36.2,16z M32.9,16.8L21.7,27.8l-5.5-5.6
	c-0.5-0.3-1.1-0.6-1.7-0.6c-1.4,0-2.4,1.1-2.4,2.4c0,0.6,0.3,1.2,0.8,1.7l7.3,7.1c0.5,0.5,1.1,0.8,1.7,0.8s1.2-0.3,1.7-0.8
	l12.9-12.7c0.5-0.5,0.8-1.1,0.8-1.7c0-1.4-1.1-2.4-2.4-2.4C33.9,16,33.3,16.3,32.9,16.8z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconCircleCheck;
