import * as React from 'react';
import StepWrapper from '../StepWrapper/StepWrapper';
import DeliveryStepDone from './DeliveryStepDone';
import DeliveryStepEdit from './DeliveryStepEdit';
import { CheckoutStep } from '../StepsContext/StepsContext';
import TempOrderTotalsContextContainer from 'contexts/TempOrderTotalsContext/TempOrderTotalsContextContainer';

const DeliveryStep = () => (
  <StepWrapper
    step={CheckoutStep.Delivery}
    title="Delivery"
    EditComponent={
      <TempOrderTotalsContextContainer>
        <DeliveryStepEdit />
      </TempOrderTotalsContextContainer>
    }
    DoneComponent={<DeliveryStepDone />}
  />
);

export default DeliveryStep;
