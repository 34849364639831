import * as React from 'react';
import AddressDescription from '../AddressDescription/AddressDescription';
import OrderContext from 'contexts/OrderContext/OrderContext';

const ShippingAddressStepDone = () => {
  const { order } = React.useContext(OrderContext);

  return (
    <>
      <div className="paragraph-bold-mobile mb-1">Order Email</div>
      <div className="paragraph-mobile mb-4">{order.email}</div>
      <div className="paragraph-bold-mobile mb-1">Shipping address</div>
      <AddressDescription address={order.shippingAddress} />
    </>
  );
};
export default ShippingAddressStepDone;
