import { Order } from 'api/orders/types';
import OrderContext from 'contexts/OrderContext/OrderContext';
import * as React from 'react';
import CheckoutNavbar from './CheckoutNavbar/CheckoutNavbar';
import Footer from 'styleguide/components/Footer/Footer';
import Steps from './Steps/Steps';
import OrderConfirmation from './OrderConfirmation/OrderConfirmation';
import Meta from 'styleguide/components/Meta/Meta';
import { getCurrentOrder } from 'contexts/OrderContext/actions';
import { useLocation } from 'react-router-dom';
import StepsProvider from './Steps/StepsContext/StepsContext';

const CheckoutPage = () => {
  const [createdOrder, setCreatedOrder] = React.useState<Order>(null);
  const { dispatch } = React.useContext(OrderContext);
  const location = useLocation();

  React.useEffect(() => {
    if (createdOrder) {
      getCurrentOrder(dispatch, location.pathname);
    }
  }, [createdOrder]);

  return (
    <>
      <Meta
        title="Checkout"
        description=""
        keywords=""
        canonical="https://www.printivity.com/checkout"
        robots={['noindex']}
      />
      <CheckoutNavbar />
      {createdOrder ? (
        <OrderConfirmation createdOrder={createdOrder} />
      ) : (
        <StepsProvider>
          <Steps onCreateOrder={newOrder => setCreatedOrder(newOrder)} />
        </StepsProvider>
      )}
      <Footer withoutSubscriptionForm />
    </>
  );
};

export default CheckoutPage;
