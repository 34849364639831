import * as React from 'react';
import cn from 'classnames';
import css from './IconCard.scss';

type UiAlignment = 'top' | 'center' | 'bottom';

interface CardProps {
  callout?: boolean;
  alignment?: UiAlignment;
  bottomAlign?: boolean;
  header?: React.ReactNode;
  icon?: React.ReactNode;
  mainText: React.ReactNode;
  subText?: React.ReactNode;
  cardClasses?: string;
  contentClasses?: string;
}

const IconCard = ({ mainText, cardClasses, contentClasses, alignment = 'center', ...props }: CardProps) => (
  <div
    className={cn(
      css.card,
      { [css.cardCallout]: !!props.callout },
      { [css[`cardAlignment--${alignment}`]]: !!alignment },
      cardClasses,
    )}
  >
    {!!props.header && props.header}
    <div className={cn(css.cardContent, contentClasses)}>
      {!!props.icon && props.icon}
      {mainText}
      {!!props.subText && props.subText}
    </div>
  </div>
);

export default IconCard;
